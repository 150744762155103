* {
  font-family: -apple-system, blinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Roboto", "Arial", sans-serif;
    box-sizing: border-box;
}

body {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.btns {
  height: auto;
  padding-bottom: 10px;
}
.body {
  flex: 1;
  padding: 2rem 0;
  color: #202020;
  overflow-y: auto;
  font-size: 1.8rem;
  line-height: 1.5;
  -moz-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.flex-2 {
  display: flex;
  width: 100%;
  align-items: center;
}

.gray {
  color: #aaa;
}

.status {
  margin-left: 1rem;
  color: #606060;
}

.fab-wrapper {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}

.top-marg {
  margin-top: 1em;
}

p.resultText {
  margin-top: 0;
  margin-bottom: .7em;
}

@media only screen and (max-width: 64em) { 
  .body {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 40em) { 
  .body {
    font-size: 1.4rem;
  }
}
